<template>
  <div class="my-8 callHistory">
    <v-row>
      <v-col>
        <h2 class="main-color">{{ $t("Zoom Links") }}</h2>
      </v-col>
      <v-col class="text-center">
        <label class="search" for="search">
          <input
            id="inpt_search"
            class="input_search"
            v-model="search"
            @keyup.enter="getData"
            @focus="inputFocus"
            @blur="inputBlur"
            type="text"
          />
          <v-icon @click="getData" :title="$t('Search')" class="search-icon"
            >search</v-icon
          >
        </label>
      </v-col>
      <v-col :class="currentAppLocale == 'en' ? 'text-right' : 'text-left'">
        <v-menu
          v-model="filterMenu"
          :close-on-content-click="false"
          :nudge-width="200"
          offset-x
          offset-y
        >
          <template v-slot:activator="{ on }">
            <v-icon
              v-on="on"
              :title="$t('Search')"
              large
              color="#7297ff"
              @click="filterMenu = !filterMenu"
              >mdi-filter</v-icon
            >
          </template>

          <v-card class="py-4">
            <v-list>
              <v-list-item class="select">
                <v-select
                  v-model="filterData.classes"
                  multiple
                  :items="classes"
                  item-text="name"
                  item-value="id"
                  :placeholder="$t('Classes')"
                  solo
                ></v-select>
              </v-list-item>
            </v-list>

            <v-card-actions>
              <v-spacer></v-spacer>

              <v-btn color="primary" text @click="filterMenu = false">{{
                $t("Close")
              }}</v-btn>
            </v-card-actions>
          </v-card>
        </v-menu>
      </v-col>
    </v-row>
    <v-data-table
      :options.sync="options"
      :loading="loading"
      loading-text="Loading... Please wait"
      :headers="headers"
      :items="items"
      item-key="id"
      :show-select="false"
      class="elevation-1 level1 templates text-center"
      text="left"
      hide-default-footer
    >
      <template v-slot:item.host_url="{ item }">
        <v-btn icon class="mx-2" @click="copyText(item.host_url)">
          <v-icon>mdi-content-copy</v-icon>
        </v-btn>
      </template>

      <template v-slot:item.join_url="{ item }">
        <v-btn icon class="mx-2" @click="copyText(item.join_url)">
          <v-icon>mdi-content-copy</v-icon>
        </v-btn>
      </template>
    </v-data-table>
    <div class="text-center my-2">
      <v-pagination
        v-if="length > 0"
        v-model="page"
        :length="length"
        :total-visible="totalVisible"
        circle
      ></v-pagination>
    </div>
  </div>
</template>

<script>
export default {
  name: "ZoomLinks",
  data() {
    return {
      search: "",
      filterMenu: false,
      filterData: {
        classes: [],
      },
      classes: [],
      loading: false,
      options: {
        itemsPerPage: 15,
      },

      circle: true,
      page: 1,
      length: 1,
      totalVisible: 5,
      headers: [
        {
          text: this.$i18n.t("Class Name"),
          align: "start",
          sortable: false,
          value: "class_name",
        },
        {
          text: this.$i18n.t("Subject Name"),
          value: "subject_name",
          sortable: false,
        },
        {
          text: this.$i18n.t("Teacher Name"),
          value: "teacher_name",
          sortable: false,
        },
        {
          text: this.$i18n.t("Lesson"),
          value: "lesson",
          sortable: false,
        },
        {
          text: this.$i18n.t("From"),
          value: "from",
          sortable: false,
        },
        {
          text: this.$i18n.t("To"),
          value: "to",
          sortable: false,
        },
        {
          text: this.$i18n.t("Host Url"),
          value: "host_url",
          sortable: false,
        },
        {
          text: this.$i18n.t("Join Url"),
          value: "join_url",
          sortable: false,
        },
      ],
      items: [],
    };
  },
  watch: {
    page: function () {
      this.getData();
    },
    options: {
      handler() {
        if (
          this.options.sortBy.length > 0 &&
          this.options.sortDesc.length > 0
        ) {
          this.getData();
        }
      },
      deep: true,
    },
    filterData: {
      handler() {
        this.getData();
      },
      deep: true,
    },
  },
  methods: {
    copyText(str) {
      navigator.clipboard.writeText(str);
    },

    getData() {
      this.$http
        .get(
          this.getApiUrl +
            "/online_meeting/zoom_classes/show?page=" +
            this.page +
            "&class=" +
            this.filterData.classes +
            "&search=" +
            this.search,

          {
            headers: {
              Authorization: "Bearer " + localStorage.token,
            },
          }
        )
        .then((response) => {
          this.items = response.data.data.data;
          this.page = response.data.data.current_page;
          this.options.itemsPerPage = response.data.data.per_page;
          this.length = response.data.data.last_page;
        });
    },
    getGradesClasses() {
      this.$http
        .get(this.getApiUrl + "/discussionboard/getClasses", {
          headers: {
            Authorization: "Bearer " + localStorage.token,
          },
        })
        .then((response) => {
          this.classes = response.data.data;
        });
    },
  },
  mounted() {
    this.getGradesClasses();
    this.getData();
  },
};
</script>

<style></style>
